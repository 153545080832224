<template>
  <div class="home">
    <Banner/>


    <!-- <div class="m h20">123</div>
    <div class="m h20">123</div> -->
    <!--    十大品牌-->
    <!-- <div class="m adCon ">
      <div class="img"><img
          src="https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2023-07-20/bf7bdc21-9699-4c83-8f27-8c070f426877.jpg"
          alt="">
      </div>
    </div> -->

    <!-- 十大品牌 -->
    <Tabs/>

    <div class="m adCon">
      <Ad :num="2" type="招商广告513*66"/>
    </div>

    <div class="m adCon">
      <Ad :num="4" type="首页广告位2"/>
    </div>

    <div class="m">

      <hot-videos/>
      <div class="m adCon">
        <Ad :num="2" type="招商广告513*66"/>
      </div>
      <div class="adCon">
        <Ad :num="4" type="首页广告位3"/>
      </div>
      <brand-news style="margin-bottom: 20px"/>
      <div class="m adCon">
        <Ad :num="2" type="招商广告513*66"/>
      </div>
      <div class="adCon">
        <Ad :num="4" type="首页广告位5"/>
      </div>
<!--      <join style="margin-bottom: 20px"/>-->

      <div class="m adCon">
        <Ad :num="2" type="招商广告513*66"/>
      </div>

      <div class="adCon">
        <Ad :num="4" type="首页广告位6"/>
      </div>
      <product/>


      <div class="adCon">
        <Ad :num="4" type="首页广告位7"/>
      </div>
    </div>

    <!-- <Content /> -->
    <!-- <Join /> -->
  </div>
</template>

<script>
import Banner from "@/components/home/banner/Banner.vue";
import Ad from "@/components/Ad/index.vue";
import hotVideos from "./index/components/hotVideos/hot-videos.vue";
import brandNews from "./index/components/brandNews/brand-news.vue";
import join from "./index/components/join/join.vue";
import product from "./index/components/product/product.vue";

import Tabs from "@/components/home/Tabs.vue";
import Content from "@/components/home/content/Content.vue";

export default {
  name: "HomeView",
  components: {
    Banner,
    Tabs,
    Content,
    Ad,
    hotVideos,
    brandNews,
    join,
    product,
  },

  metaInfo() {
    return {
      title: "门窗之家 - 您身边的门窗专家！",
      // link: [{ rel: "icon", href:"" }],
      // meta: [
      //   {
      //     name: "keywords",
      //     content: this.info.keyword,
      //   },
      //   {
      //     name: "content",
      //     content: this.info.summary,
      //   },
      //   {
      //     name: "description",
      //     content: this.info.summary,
      //   },
      //   {
      //     property: "site_name",
      //     content: this.info.name,
      //   },
      // ],
    };
  },

};
</script>
<style scoped lang="scss">
.m,
.banner,
.home {
  overflow: inherit;

  .adCon {
    // height: px;
    margin-bottom: 20px;
    // border: 1px solid red;
    .img {
      // border: 1px solid red;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
</style>
