<template>
  <div class="ad">
    <div class="item" v-for="(item, index) in adList" :key="index">
      <a :href="item.linkUrl" target="_blank">
        <img :src="item.imgUrl"/>
      </a>
    </div>
  </div>
</template>

<script>
import { getAd } from "@/api/ad/ad";
export default {
  name: "Ad",
  data() {
    return {
        adList:[]
    };
  },
  props: {
    num: {
      type: Number,
      default: 4,
    },
    type: {
      type: String,
      default: "",
    },
  },
  mounted() {
    var query = {
      pageNum: 1,
      pageSize: 20,
      type: this.type,
    };
    getAd(query).then((res) => {

        var num = this.num
        num = res.rows.length

        // alert(num)
        for(let i=0;i<num;i++){
            this.adList.push(res.rows[i])
        }
    });
  },
};
</script>

<style scoped lang="scss">
.ad {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  justify-content: space-between;
  .item {
    height: 60px;
    // width: 50%;
    overflow: initial;
    // border: 1px solid red;
    // background-color: #999;
    img{
      // border: 1px solid red;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
  }
}
</style>