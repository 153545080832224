<template>
  <div class="NewsListByCategory" v-if="show">


    <!--  {{$route.query.item}}-->
    <!-- 面包屑   -->
    <el-row>
      <el-col :span="10" :offset="3">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="'/news'">新闻资讯</el-breadcrumb-item>
          <el-breadcrumb-item>
            {{ newsCategoryFormat(newsCategoryId) }}
          </el-breadcrumb-item>

        </el-breadcrumb>
      </el-col>
    </el-row>
    <!--   分割线 -->
    <el-row>
      <el-col :span="20" :offset="2">
        <el-divider></el-divider>
      </el-col>
    </el-row>

    <el-container>
      <el-aside width="8%"></el-aside>
      <el-main width="55%">
        <!--    {{newsListByCategory}}-->
        <div v-for="item in newsListByCategory" :key="item.id">
          <router-link :to="{path:'/newsInfo',query:{id: item.id}}">
            <div class="navContent">
              <img :src="item.titleImgUrl" alt="">
              <div class="right">

                <div class="contentTop">
                  <h3>{{ item.title }}</h3>
                  <p>{{ item.introduction }}</p>
                </div>
                <div class="contentBottom">
                  <!--              <div class="slot"><a href="">千鼎木门</a>&nbsp&nbsp&nbsp|&nbsp&nbsp<a href="">千鼎木门加盟</a></div>-->
                  <div class="date">{{ parseTime(item.createTime) }}</div>
                </div>

              </div>
            </div>
          </router-link>
        </div>

        <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNum"
                    :limit.sync="queryParams.pageSize"
                    @pagination="getList"/>
      </el-main>
      <el-aside width="26%">
        <div class="content">
          <div v-for="item in dict.type.news_category_info" :key="item.value">
            <router-link :to="{path:'/newsListByCategory',query:{newsCategoryId: item.value}}">
              <div style="height:20px"></div>
              <el-tag :type="newsCategoryId==item.value?'danger':'success'">     {{ item.label }}      </el-tag>
            </router-link>
          </div>
        </div>
      </el-aside>
      <el-aside width="8%"></el-aside>
    </el-container>
  </div>


</template>


<script>
import {getNewList} from "@/api/news/news";

export default {
  name: "NewsListByCategory",
  dicts: ['news_category_info'],
  data() {
    return {
      newsCategoryId: 2,
      show: undefined,
      newsListByCategory: undefined,
      total: 0,
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        name: undefined,
      },
    }
  },
  created() {
    this.newsCategoryId = this.$route.query.newsCategoryId;
    this.show = false;

    getNewList({pageNum: 1, pageSize: 10, newsCategoryId: this.newsCategoryId}).then(response => {
          // console.log("this.topNewsData");
          this.newsListByCategory = response.rows;
          this.total = response.total;
          this.show = true;
          console.log("按分类拿新闻", this.newsListByCategory);
          // console.log("this.topNewsData");
        }
    );

  },
  methods: {
    newsCategoryFormat(id) {
      // console.log(this.dict.type.news_category_info);
      return this.selectDictLabel(this.dict.type.news_category_info, id);
    },
    getList() {
      getNewList({
        pageNum: this.queryParams.pageNum,
        pageSize: this.queryParams.pageSize,
        newsCategoryId: this.newsCategoryId
      }).then(response => {
            // console.log("this.topNewsData");
            this.newsListByCategory = response.rows;
            this.total = response.total;
            this.show = true;
            console.log("按分类拿新闻", this.newsListByCategory);
            // console.log("this.topNewsData");
          }
      );
    }
  },
  watch: {
    // 方法1
    '$route'(to, from) { //监听路由是否变化
      //  console.log(to,from)
      // if (to.path == "/") { //跳转到哪个页面
      //   location.reload()
      // }
      if (to.query.newsCategoryId !== from.query.newsCategoryId) {
        this.newsCategoryId = to.query.newsCategoryId;
        this.show = false;
        getNewList({pageNum: 1, pageSize: 10, newsCategoryId: this.newsCategoryId}).then(response => {
              // console.log("this.topNewsData");
              this.newsListByCategory = response.rows;
              this.total = response.total;
              this.show = true;
              console.log("重新按分类拿新闻", this.newsListByCategory);
              // console.log("this.topNewsData");
            }
        );
      }
    }
  },


}


</script>


<style scoped>

span.el-tag.el-tag--light {
  font-size: 40px;
  height: 50px;
  width: 300px;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}

.contentBottom .date {
  color: #909399;
}

.contentBottom .slot, a {

  color: #4591e5;
}

.contentTop {
  /* border: 1px solid red; */
  height: 5.95vw;
}

.contentTop p {
  font-size: 0.9vw;
  color: #909399;
  line-height: 1.5vw;
  overflow: hidden;
  /* border: 1px solid red; */
  text-overflow: ellipsis;
  /* white-space:nowrap; */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.contentTop h3 {
  font-size: 1.3vw;
  font-weight: normal;
  margin-bottom: 0.2vw;
}

.contentBottom {
  font-size: 0.9vw;
  /* border: 1px solid red; */
  height: 1.05vw;
  display: flex;
  justify-content: space-between;
  line-height: 1.05vw;
  /* line-height: 150%; */
}

.right {
  /* border: 1px solid red; */
  width: 100%;
  margin-left: 1vw;
}

.navContent img {
  width: 9vw;
  height: 7vw;
}

.navContent {
  display: flex;
  border-bottom: 2px solid #eeeeee;
  padding: 1vw 0;
}

.NewsListByCategory {
  /* width: 1920px; */
  padding: 20px;
  background-color: #eeeeee;
  overflow: initial;
}

</style>