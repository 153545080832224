<template>
    <div class="join">
        <div class="itit">
            <div class="bt ititsdpp">门窗加盟</div>
        </div>

        <div class="con">
            <a target="_blank" class="adImg" v-if="adListSHow" :href="adList[0].linkUrl">
                <img :src="adList[0].imgUrl" />
            </a>
            <!-- <img src v-else /> -->

            <div class="peoples">
                <el-table :data="joinData" style="width: 100%" height="250" class-name="tableHead">
                    <el-table-column prop="name" label="姓名" width="80" align="center" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="phone" label="手机" width="115" align="center"></el-table-column>
                    <el-table-column prop="area" label="代理区域" width="150" align="center" show-overflow-tooltip></el-table-column>
                </el-table>
                <!-- <div class="brands" style="border:1px solid red">
                    <div class="brands__item" v-for="(item,index) in brands" :key="index">
                        {{item.name}}
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import { getBrand } from '@/api/brand/brand.js'
import $ from 'jquery'
import { getAd } from '@/api/ad/ad'
export default {
    name: 'join',
    data() {
        return {
            Interval: undefined,
            adList: [],
            adListSHow: false,
            joinData: [
                {
                    name: '程金海',
                    phone: '138*****042',
                    area: '安徽省宣城市泾　县',
                },
                {
                    name: '刘爱金',
                    phone: '136*****559',
                    area: '福建省南平市延平区',
                },
                {
                    name: '谭细芳',
                    phone: '138*****675',
                    area: '湖北省咸宁市通山县',
                },
                {
                    name: '马一红',
                    phone: '135*****498',
                    area: '湖南省张家界市永定区',
                },
                {
                    name: '陈雪龙',
                    phone: '159*****388',
                    area: '江苏省南京市溧水县',
                },
                {
                    name: '张炉明',
                    phone: '181*****918',
                    area: '江西省',
                },
                {
                    name: '张经耀',
                    phone: '181*****918',
                    area: '江西省抚州市',
                },
                {
                    name: '湘西北土特产',
                    phone: '135*****280',
                    area: '湖南省',
                },
                {
                    name: '郑维明',
                    phone: '138*****679',
                    area: '贵州省',
                },
            ],
            brands:[]
        }
    },
    updated() {
        //  console.log($(".el-table__body")[0])
        // $(".el-table__body").fadeOut(5000);
        let tableBody = $('.el-table__body')
        // $(".el-table__body").click(function () {
        //   $(".el-table__body").animate({
        //     top: "-50px",
        //   });
        // });
        let offset = 0

        // tableBody.children().eq(1).children().eq(1);
        let tableHeight = tableBody.children().eq(1).children().eq(1).height()

        let l = tableBody.children().eq(1).children().length
        console.log(tableBody.children().eq(1).children().eq(0))

        var Interval = setInterval(function () {
            // console.log(offset);
            offset = offset + 1
            if (offset > l / 2) {
                offset = 0
                tableBody.animate(
                    {
                        top: '0px',
                    },
                    0
                )
            }

            tableBody.animate({
                top: -tableHeight * offset + 'px',
            })

            //     tableBody.animate({
            //   top: -tableHeight + "px",
            // });
            //  tableBody.animate({
            //   top: "0px",
            // },0);
            // tableBody.children().eq(1).append(tableBody.children().eq(1).children().eq(0))

            // tableBody.append(tableBody.children().eq(1).children().eq(0))
        }, 2500)
    },
    mounted() {
        this.joinData = this.joinData.concat(this.joinData)
        var query = {
            pageNum: 1,
            pageSize: 2,
            type: '加盟海报',
        }
        getAd(query).then((res) => {
            console.log('加盟海报', res)
            this.adList = res.rows
            this.adListSHow = true
            // console.log(res);
        })

         var query = {
            pageNum: 1,
            pageSize: 20,
        }
        getBrand(query).then((res) => {
            this.brands = res.rows
        })

    },
    beforeDestroy() {
        clearInterval(this.Interval)
    },
}
</script>

<style scoped lang="scss">
.itit {
    border-bottom: #eee 3px solid;
    position: relative;
    height: 50px;
    margin-bottom: 11px;
    overflow: inherit;
    .bt {
        font-size: 24px;
        height: 48px;
        line-height: 48px;
        position: absolute;
        bottom: -3px;
        left: 0px;
        z-index: 2;
    }
}

.ititsdpp {
    background: url('@/assets/image/title/mcjm.png') left center no-repeat;
    background-size: auto 26px;
    text-indent: 30px;
    border-bottom: #d4237a 3px solid;
}

div {
    overflow: initial;
}
.join {
    .con {
        padding: 20px 0;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 250px;
        gap: 20px;
        // border: 1px solid red;
        height: 250px;
        .adImg {
            // border: 1px solid red;
            display: block;
            img {
                // width: 250px;
                object-fit: cover;
                height: 100%;
                width: 100%;
            }
        }

        .peoples {
            // border: 1px solid red;
            // width: 100%;
            display: flex;
            gap: 20px;
            .brands {
                border: 1px solid red;
                width: 100%;
            }
        }

        .tableHead {
        }
        /deep/ .el-table {
            .el-table__header-wrapper {
                .has-gutter {
                    tr {
                        th {
                            background-color: #f1f1f1;
                            padding: 7px;
                            .cell {
                                color: #333333;
                            }
                        }
                    }
                }
            }
            .el-table__body-wrapper {
                overflow: hidden;
                .el-table__body {
                    position: absolute;
                    // transform: scaleY();
                }
            }
        }
    }
}
</style>