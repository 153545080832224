<template>
  <div class="footer">
    <div class="m">
      <div class="footerl">
        <div class="fmenu">
          <a href="https://www.cnnmumen.com/">网站首页</a> &nbsp;|&nbsp;

          <router-link to='/about/index'>关于我们</router-link>
          &nbsp;|&nbsp;
          <router-link to='/about/contact'>联系方式</router-link>
         



        </div>
        <div class="fcopy">
          Copyright © 门窗之家（www.cnnmumen.com）2019 All Rights Reserved.<br />手机：15306795688
          在线QQ：3345521831
          <a href="https://beian.miit.gov.cn/" rel="nofollow" target="_blank"
            >沪ICP备2022034676号-1</a
          ><br />免责声明：本站上会员自行发布的信息的真实性、准确性和合法性由发布会员负责，对此不承担任何保证责任.
        </div>
      </div>
      <div class="footerr">
        <div>
          <img src="https://www.cnmumen.com/skin/2019/img/topwxewm.jpg" />
          <p>官方微信</p>
        </div>
        <div>
          <img src="https://www.cnmumen.com/skin/2019/img/topmobileewm.jpg" />
          <p>手机版</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.footer {
  margin-top:20px;
  background: #111;
  width: 100%;
  color: #999;
  // border: 1px solid red;
  overflow: inherit;

  .m {
    box-sizing: border-box;
    // height: 100%;
    padding: 7.5px;
    /* border: 1px solid red; */
    display: flex;
    justify-content: space-between;
    // border: 1px solid red;
    background: #111;

    .footerr {
      display: flex;
      justify-content: space-around;
      div {
        padding: 7.5px;
        width: 60px;
        margin: 0 15px;
        p {
          text-align: center;
        }
        img {
          width: 100%;
        }
      }
    }
    .footerl {
      white-space: nowrap;
      width: 80%;
      /* border: 1px solid red; */
      padding: 7.5px;
      .fmenu {
        font-size: 15px;
        line-height: 30px;
        a {
          color: #999;
        }
      }
      .fcopy {
        font-size: 15px;
        line-height: 22px;
        margin-top: 6px;
        color: #666;
        a {
          color: #666;
          &:hover{
                text-decoration: underline;
          }
        }
      }
    }
  }
}
</style>