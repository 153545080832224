<template>
  <div class="videos">
    <slot class="title"></slot>
    <div class="v">

        <div v-for="item in 20" :key="item" class="video"></div>

    </div>
  </div>
</template>

<script>
export default {
  name: "Videos",
};
</script>

<style scoped>
.videos{
background-color: #fff;
/* padding: 0.5vw; */
height: 100%;
}
.v {
  /* height: 20vw; */
  /* height: 100%; */
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 0.5vw;
  /* overflow: hidden; */
}
.video {
  border: 1px solid red;
  width: 10.4vw;
  height: 8vw;
  margin: 0.5vw;
}
</style>