<template>
  <div class="head">
    <div class="m">
      <div class="head">
        <ul>
          <li style="padding-right: 15px">门窗之家，您身边的门窗专家！</li>
        </ul>

        <ul
          id="webarr"
          @mouseenter="mouseenter()"
          @mouseleave="mouseleave($event)"
        >
          <a href="##" target="_blank">
            <li>集成灶之家</li>
          </a>
          <a href="##" target="_blank">
            <li>门窗之家</li>
          </a>
          <a href="##" target="_blank">
            <li>顶墙之家</li>
          </a>
          <a href="##" target="_blank">
            <li>硅藻泥之家</li>
          </a>
          <a href="##" target="_blank">
            <li>贝壳粉之家</li>
          </a>
          <a href="##" target="_blank">
            <li>建材之家</li>
          </a>
          <a href="##" target="_blank">
            <li>晾衣架之家</li>
          </a>
          <a href="##" target="_blank">
            <li>墙布窗帘之家</li>
          </a>
          <a href="##" target="_blank">
            <li>全屋定制之家</li>
          </a>
        </ul>

        <ul>
          <li id="destoon_member" style="position: relative">
            <a
              href="javascript:;"
              class="hyzx"
              rel="nofollow"
              @mouseenter="mouseenter()"
              @mouseleave="mouseleave()"
              >行业中心
              <div class="sanjiao"></div
            ></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Head",
  methods: {
    mouseenter() {
      document.getElementsByClassName("sanjiao")[0].style.display = "block";
      document.getElementById("webarr").style.display = "block";
      // console.log(a)
    },
    mouseleave() {
      document.getElementsByClassName("sanjiao")[0].style.display = "none";
      document.getElementById("webarr").style.display = "none";
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.head {
  width: 100%;
  height: 42px;
  background: #f6f6f6;
  overflow: initial;
  ul {
    display: flex;
    li {
      height: 42px;
      line-height: 42px;
      color: #999;
      a {
        color: #999;
        &:hover {
          color: #f60;
        }
      }
    }
    &:nth-child(1) li:nth-child(1) {
      background: url("../assets/image/head_home.png") left center no-repeat;
      background-size: auto 14px;
      text-indent: 18px;
    }
  }
  .m {
    overflow: initial;
    .head {
      position: relative;
      overflow: initial;
      display: flex;
      justify-content: space-between;
      #destoon_member {
        overflow: initial;
        float: none;
        text-align: right;
        a .sanjiao {
          display: none;
        }
        .hyzx {
          position: relative;
          padding: 10px;
          height: 42px;

          .sanjiao {
            /* display: inline-block; */
            display: none;
            position: absolute;
            right: 15px;
            /* right: 30%;*/
            bottom: -34%;
            width: 20px;
            height: 20px;
            background-color: #f60;
            transform: rotate(136deg);
          }
        }
      }
      #webarr {
        display: none;
        position: absolute;
        right: 0.3%;
        top: 40px;
        z-index: 20;
        overflow: initial;
        width: 140px;
        display: none;
        li {
          width: 100%;
          text-align: center;
          float: none;
          margin: 0;
          padding: 0;
          background-color: #9a3732;
          color: #fff;
          font-size: 16px;
          &:hover {
            background-color: #f7941c;
          }
        }
      }
    }
  }
}

</style>
