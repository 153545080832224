import request from '@/utils/request'
export function getBrand(query) {
    return request({
        url: "/brand/list",
        method: "get",
        params: query,
    });
}
export function getBrandDetail(brandId) {
    return request({
        url: "/brand/" + brandId,
        method: "get"
    });
}
