<template>
  <div class="join" :style="{ height: height }">
    <div class="con">
      <div class="title">
        <div class="line"></div>
        <div class="t">百万直通车</div>
        <div class="line"></div>
      </div>

      <div>
        <el-form class="form" ref="form" :model="form" :rules="rules">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item prop="title">
                <el-input v-model="form.title" placeholder="您的真实姓名"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item prop="joinCategoryId">
                <el-select v-model="form.joinCategoryId" placeholder="加盟行业">
                  <el-option v-for="dict in dict.type.join_category_info" :key="dict.value" :label="dict.label"
                    :value="dict.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="24">
              <el-form-item prop="phoneNumber">
                <el-input v-model="form.phoneNumber" placeholder="请输入您的手机号码"></el-input>
                <!-- <div slot="error">
                  {{errorMsg}}
                </div> -->
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <!-- <el-select v-model="form.industry" placeholder="加盟行业">
              <el-option
               v-for="dict in dict.type.join_category_info"
                :key="dict.value"
                :label="dict.label"
                :value="dict.value"
              >
              </el-option> </el-select
          > -->
              <addressSelect @value="addressValue" v-model="form.address" />
            </el-col>
          </el-row>
        </el-form>
      </div>

      <div class="submit">
        <button @click="submit">立即提交，离财富更进一步</button>
      </div>
    </div>
  </div>
</template>

<script>
import addressSelect from "./address-select.vue";
import {add} from "@/api/join/join";
export default {
  components: {
    addressSelect,
  },
  name: "Join",
  dicts: ["join_category_info"],
  data() {
    return {
      errorMsg: "",
      form: {},
      rules: {
        receiver: [
          { required: true, message: "用户名称不能为空", trigger: "blur" },
          {
            min: 2,
            max: 10,
            message: "用户名称长度必须介于 2 和 20 之间",
            trigger: "blur",
          },
        ],

        phoneNumber: [
          {
            required: true,
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
        location: [
          { required: true, message: "详细地址不能为空", trigger: "blur" },
          {
            min: 5,
            max: 30,
            message: "详细地址长度必须介于 5 和 30 之间",
            trigger: "blur",
          },
        ],
      },
    };
  },
  props: {
    height: {
      type: String,
      default: "380px",
    },
  },
  methods: {
    addressValue(e) {
      console.log(e);
      this.$set(this.form, "address", e.area);
    },
    submit() {
      let address;
      if (this.form.address) {
        address = this.form.address.split("/");
        this.$set(this.form, "addressProvince", address[0]);
        this.$set(this.form, "addressCity", address[1]);
        this.$set(this.form, "addressCountry", address[2]);
        console.log(this.form);
      }else{
        this.$message({ message: '请填写地址', type: 'error' });
        return ;
      }
      
      if(/^1[3|4|5|6|7|8|9][0-9]\d{8}$/.test(this.form.phoneNumber)){

      }else {
        this.$message({ message: '请输入正确的电话号码', type: 'error' });
        return ;
      }

      if(this.form.joinCategoryId==undefined || this.form.joinCategoryId==null){
        this.$message({ message: '请选择加盟行业', type: 'error' });
        return ;
      }


      add(this.form).then(response=>{
          this.$message({message: '信息已提交，我们会联系您根据预留电话', type: 'success' });
      });

    },
  },
};
</script>

<style scoped lang="scss">
.join {

  // height: 100px;
  .con {
    background-color: #B80D0D;
    height: 100%;
    box-sizing: border-box;
    padding: 30px;
    display: grid;
    align-content: space-between;

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;

      .line {
        height: 5px;
        width: 28%;
        background-color: white;
      }

      .t {
        font-weight: 600;
        color: white;
        font-size: 24px;
      }
    }

    .form {
      display: grid;
      // gap: 10px;
    }

    /deep/ .el-form {
      .el-form-item {
        margin-bottom: 0px;
        overflow: initial;

        .el-form-item__content {
          overflow: initial;
        }
      }
    }

    .submit {

      // background-color: #af4b42;
      button {
        cursor: pointer;
        background-color: white;
        border: 0;
        width: 100%;
        border-radius: 6px;
        color: #B80D0D;
        padding: 15px 0;
      }
    }
  }
}
</style>