<template>
    <div class="nav">
        <div class="m">
            <ul>
                <li class="lion" :class="active == index ? 'active' : ''" v-for="(item, index) in navList" :key="index" @click="switchPage(item,index)">
                    <router-link :to="item.url">{{ item.title }}</router-link>

                    <div class="select"></div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Nav',
    data() {
        return {
            active: 0,
            navList: [
                { title: '首页', url: '/index' },
                { title: '门窗品牌', url: '/brand' },
                { title: '新闻资讯', url: '/news' },
                { title: '产品精选', url: '/productSell' },
                { title: '直击展会', url: '/special' },
                { title: '十大品牌', url: '/top10' },
                // { title: '栏目', url: '/column' },
            ],
        }
    },
    watch: {
        //动态监听路由变化 -以便动态更改导航背景色事件效果等
        $route(to, from) {
            for (let i in this.navList) {
                if (this.navList[i].url == to.path) {
                    this.active = i
                    break
                }
            }
        },
    },

    mounted() {
        for (let i in this.navList) {
            if (this.navList[i].url == this.$route.path) {
                this.active = i
                break
            }
        }
    },
    methods:{
        switchPage(item,index){
            this.active = index
            this.$router.push({path : item.url })
        }
    }
}
</script>

<style scoped lang="scss">
.nav {
    // border: 1px solid red;
    width: 100%;
    height: 58px;
    // background: #9a3732;
    border-bottom: 6px solid $aoyin-color-primary;
    overflow: inherit;
    overflow: initial;
    .m {
        border-bottom: 6px solid $aoyin-color-primary;
        // background: #9a3732;
        ul {
            overflow: initial;
            // border: 1px solid red;
            display: flex;
            justify-content: space-around;
            .active {
                .select {
                    display: block;
                }
            }
            li {
                cursor: pointer;
                overflow: initial;
                position: relative;
                text-align: center;
                /* float: left; */
                height: 58px;
                width: 135px;
                display: grid;
                align-content: space-between;
                font-size: 16px;
                // border: 1px solid red;
                /* background: url("./assets/image/navli.gif") left center no-repeat; */
                a {
                    line-height: 55px;
                    height: 40px;
                    // border: 1px solid red;
                    color: #000;
                    display: block;
                    // height: 100%;
                    // height: 40px;
                }
                &:hover {
                    .select {
                        display: block;
                    }
                    a {
                        color: #af4b42;
                    }
                    // border: 1px solid red;
                }
                .select {
                    display: none;
                    width: 0px;
                    height: 0px;
                    margin: auto;
                    border-top: 0px solid transparent;
                    border-bottom: 10px solid $aoyin-color-primary;
                    border-left: 12px solid transparent;
                    border-right: 12px solid transparent;
                }
            }
        }
    }
}
</style>