<template>
  <div class="brandNews">
    <div class="itit">
      <div class="bt ititsdpp">品牌资讯</div>
    </div>

    <div class="con">
      <div class="left">
        <News />
      </div>

      <div class="right">
        <div class="ad">
          <div class="item" v-for="(item, index) in adList" :key="index">
            <a :href="item.linkUrl" target="_blank"
              ><img :src="item.imgUrl"
            /></a>
          </div>
        </div>

        <div class="newsPlate">
          <div class="tabs">
            <div
              class="tab"
              :class="tabActive == index ? 'tab-active' : ''"
              v-for="(item, index) in newsTab"
              :key="index"
              @click="changeNewsTab(item.id,index)"
            >
              {{ item.name }}
            </div>
          </div>

          <div class="newsPlate__con" v-loading="loading">
            <!-- <div v-if="loading" class="loading">

            </div> -->
            <div class="news" v-for="(item, index) in newsList" :key="index" @click="newsInfo(item.id)" >
              <span class="news__title">{{ item.title }}</span>

              <span class="news__time">{{ item.createTime | dateFormat }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAd } from "@/api/ad/ad";
import { getNewCategory, getNewList } from "@/api/news/news";
import News from "./news.vue";
export default {
  name: "brandNews",
  components: {
    News,
  },
  data() {
    return {
      adList: [],
      newsTab: [],
      tabActive: 0,
      newsList: [],
      loading:true
    };
  },
  mounted() {
    var query = {
      pageNum: 1,
      pageSize: 10,
      type: "首页广告位4",
    };
    getAd(query).then((res) => {
      for (let i = 0; i < 3; i++) {
        this.adList.push(res.rows[i]);
      }
    //   console.log(res);
    });

    getNewCategory().then((res) => {
      for (let i = 0; i < 4; i++) {
        this.newsTab.push(res.data[i]);
      }
      this.getNews(res.data[0].id);
    });
  },
  methods: {
    changeNewsTab(id,index) {
        this.tabActive = index
      this.getNews(id);
    },
    getNews(id) {
      this.loading = true
      var quary = {
        pageNum: 1,
        pageSize: 4,
        newsCategoryId: id,
      };
      getNewList(quary).then((res) => {
        // console.log(res);
        this.newsList = res.rows;
        this.loading = false
      });
    },
    newsInfo(id){
         this.$router.push({path : "/newsInfo" ,query:{id}})
    }
  },
};
</script>

<style scoped lang="scss">
.brandNews {
  overflow: initial;
  
  box-sizing: border-box;
  height: 100%;
  //   border: 1px solid red;
  .con {
    padding: 20px 0;
    height: 300px;
    // border: 1px solid red;
    box-sizing: border-box;
    display: flex;
    overflow: initial;
    gap: 20px;
    .left {
      width: 40%;
    }
    .right {
      width: 60%;
      // border: 1px solid red;
      overflow: initial;
      .ad {
        margin-bottom: 10px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 10px;
        justify-content: space-between;
        height: calc(40% - 10px);
        // border: 1px solid red;
        .item {
          // border: 1px solid red;
          height: 100%;
          // background-color: #999;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            // border: 1px solid red;
          }
        }
      }
      .newsPlate {
        height: 60%;
        border: 1px solid #eae9e9;
        display: flex;
        .tabs {
          width: 15%;
          display: grid;
          align-content: space-between;
          grid-template-rows: 1fr 1fr 1fr 1fr;
          .tab {
            cursor: pointer;
            // height: 100%;
            // border: 1px solid red;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #f1f1f1;
            padding: 12px 6px;
            box-sizing: border-box;
          }
          .tab-active {
            border-left: 1px solid #af4b42;
            color: #af4b42;
            background-color: white;
          }
        }
        &__con {
          width: 85%;
          //   border: 1px solid red;
          display: grid;
          grid-template-rows: 1fr 1fr 1fr 1fr;
          .news {
            cursor: pointer;
            // height: 100%;
            // border: 1px solid red;
            display: flex;
            align-items: center;
            padding: 0 10px;
            justify-content: space-between;
            border-bottom: 1px solid #f1f1f1;
            &__title {
              width: 80%;
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
            }
            &__time {
              width: 20%;
              text-align: center;
            }
            &:hover {
              color: #af4b42;
            }
          }
        }
      }
    }
  }
}
.itit {
  border-bottom: #eee 3px solid;
  position: relative;
  height: 50px;
  margin-bottom: 11px;
  overflow: inherit;
  .bt {
    font-size: 24px;
    height: 48px;
    line-height: 48px;
    position: absolute;
    bottom: -3px;
    left: 0px;
    z-index: 2;
  }
}

.ititsdpp {
  background: url("@/assets/image/title/ppzx.png") left center no-repeat;
  background-size: auto 26px;
  text-indent: 30px;
  border-bottom: #d4237a 3px solid;
}
</style>