<template>
    <div class="newsmod" :style="{'height':height}">
        <!-- <slot class="title">

        </slot>-->
        <div class="title">
            <div class="titleText">
                <img src="@/assets/image/titpic.png" alt height="70%" />
                <p>{{ title }}</p>
            </div>

            <div class="switch" v-if="!modes.separate">
                <a @click="switchFun">换一批</a>
            </div>
        </div>

        <div class="content">
            <div class="top" v-if="modes.top">
                <div class="imgText">
                    <img src alt />
                    <div v-if="t.p">
                        <h3>{{ t.h }}</h3>
                        <p>{{ t.p }}</p>
                    </div>

                    <div v-if="!t.p">
                        <h3 style="-webkit-line-clamp: 3;line-height: 24px;">{{ t.h }}</h3>
                    </div>
                </div>
            </div>

            <div class="middle" v-if="modes.text">
                <div class="p" v-for="item in data" :key="item.id">
                    <router-link :to="{path:'/newsInfo',query:{id: item.id}}">{{ item.brandName }} | {{ item.title }}</router-link>
                </div>
            </div>

            <div v-if="modes.imgText" v-for="item in data" :key="item.id">
                <router-link :to="{path:'/newsInfo',query:{id: item.id}}">
                    <div class="imgText">
                        <img :src="item.titleImgUrl" alt />
                        <div v-if="item.introduction">
                            <h3>{{ item.title }}</h3>
                            <p>{{ item.introduction }}</p>
                        </div>

                        <div v-if="!item.introduction">
                            <h3 style="-webkit-line-clamp: 3;line-height: 24px;">{{ item.title }}</h3>
                        </div>
                    </div>
                </router-link>
            </div>

            <div class="list" v-if="modes.list">
                <div class="p" v-for="item in 10" :key="item">
                    <span :style="item<4 ? {color:'red'} : {}">{{ item }}</span>
                    屋富球铝业| 第六届中国大定制家居营销发展论坛暨品牌典重磅揭
                </div>
            </div>

            <div class="separate" v-if="modes.separate">

                <el-row :gutter="20">
                    <el-col :span="12" class="ellipsis"  v-for="item in data" :key="item"> 
                        <router-link :to="{path:'/newsInfo',query:{id: item.id}}" class="wchange">{{ item.brandName }} | {{ item.title }}</router-link>
                    </el-col>
                
                </el-row>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'NewsMod',
    data() {
        return {
            t: { id: 0, p: '', h: '家具头条家具头家具头条条家具头条家具家具头条家具头条家具头条家具头' },
            d: [
                {
                    id: 0,
                    p: '什么是一扇好门窗？如果说钢筋水泥是房子的筋骨，那么门窗则是房子对外的眼睛，传达着建筑整体的气质和精神。那如何经营好一家门窗专卖店？他最早从塑钢窗做起，为顺应市场需求',
                    h: '家具头条家具头家具头条条家具头条家具家具头条家具头条家具头条家具头',
                },
                {
                    id: 1,
                    p: '什么是一扇好门窗？如果说钢筋水泥是房子的筋骨，那么门窗则是房子对外的眼睛，传达着建筑整体的气质和精神。那如何经营好一家门窗专卖店？他最早从塑钢窗做起，为顺应市场需求',
                    h: '家具头条家具头家具头条条家具头条家具家具头条家具头条家具头条家具头',
                },
                {
                    id: 2,
                    p: '什么是一扇好门窗？如果说钢筋水泥是房子的筋骨，那么门窗则是房子对外的眼睛，传达着建筑整体的气质和精神。那如何经营好一家门窗专卖店？他最早从塑钢窗做起，为顺应市场需求',
                    h: '家具头条家具头家具头条条家具头条家具家具头条家具头条家具头条家具头',
                },
                {
                    id: 3,
                    p: '什么是一扇好门窗？如果说钢筋水泥是房子的筋骨，那么门窗则是房子对外的眼睛，传达着建筑整体的气质和精神。那如何经营好一家门窗专卖店？他最早从塑钢窗做起，为顺应市场需求',
                    h: '家具头条家具头家具头条条家具头条家具家具头条家具头条家具头条家具头',
                },
                { id: 4, p: '', h: '家具头条家具头家具具家具头条条家具头条家具家具头条家具头条家具头条家具头' },
            ],
            modesTem: { top: false, text: true, imgText: false, list: false , separate:false},
        }
    },
    props: {
        data: [],
        mode: {
            type: Number,
            default: 0,
        },
        title: {
            type: String,
            default: '标题',
        },

        height: {
            type: String,
            default: '380px',
        },
    },

    computed: {
        modes() {
            switch (this.mode) {
                case 0:
                    break
                case 1:
                    this.modesTem.text = false
                    this.modesTem.imgText = true
                    break
                case 2:
                    this.modesTem.top = true
                    // this.modesTem.imgText = true
                    break
                case 3:
                    this.modesTem.list = true
                    this.modesTem.text = false
                    // this.modesTem.imgText = true
                    break
                case 4:
                    this.modesTem.separate = true
                    this.modesTem.text = false
                    // this.modesTem.imgText = true
                    break
            }

            return this.modesTem
        },
    },
    methods:{
        switchFun(){
            this.$emit('switch')
        }
    }
}
</script>

<style scoped lang="scss">
.imgText {
    padding: 0 0 20px 0;
    display: flex;
    cursor: pointer;
    img {
        /* border: 1px solid red; */
        width: 100px;
        height: 80px;
    }
    div {
        line-height: 20px;
        width: 100%;
        height: 100%;
        margin-left: 20px;
        p {
            font-size: 13px;
            line-height: 18px;
            color: #909399;
            overflow: hidden;
            /* border: 1px solid red; */
            text-overflow: ellipsis;
            /* white-space:nowrap; */
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
        h3 {
            margin-bottom: 2px;
            font-weight: normal;
            font-size: 16px;
            overflow: hidden;
            /* border: 1px solid red; */
            text-overflow: ellipsis;
            /* white-space:nowrap; */
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }
}

.newsmod {
  overflow: hidden;
    // border: 1px solid red;
    background-color: #fff;
    height: 100%;
    padding: 0 20px;
    .title {
        margin-bottom: 10px;

        /* text-indent: 2.5rem; */
        border-top: #eee 1px solid;
        border-bottom: #eee 1px solid;
        padding: 5px 0;
        height: 32px;
        line-height: 32px;
        display: flex;
        justify-content: space-between;
        .titleText {
            background-size: auto 1.6vw;
            color: red;
            display: flex;
            align-items: center;
            font-size: 19px;
            height: 100%;
            img {
                margin-right: 10px;
            }
        }
    }
}

.switch {
    font-size: 14px;
    a {
        color: rgb(111, 166, 188);
        &:hover {
            color: red;
        }
    }
}

.middle {
  
    /* padding: 0.5vw 1vw; */
    .p {
        letter-spacing: 2px;
        cursor: pointer;
        margin-bottom: 13px;
        font-size: 14px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        color: #909399;
        /* border: 1px solid red; */
        &:hover {
            color: #9a3732;
        }
    }
}

.list {
    span {
        display: inline-block;
        width: 18px;
        text-align: right;
    }
    .p {
        letter-spacing: 2px;
        cursor: pointer;
        margin-bottom: 13px;
        font-size: 14px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        color: #909399;
        /* border: 1px solid red; */
        &:hover {
            color: #9a3732;
        }
    }
}
.ellipsis{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    float: left;
    
   
}
.wchange{
    float: left;
    //width: 98%;
}

/* .top div p{
    
    height: 100%;
} */
</style>