import request from '@/utils/request'
import {parseStrEmpty} from "@/utils/aoyin";

// 查询新闻详情
export function getNewList(query) {
    return request({
      url: "/newsInfo/list",
      method: "get",
      params: query,
    });
  }

  // 查询同类新闻详情
export function getSimiliarNewList(query) {
  return request({
    url: "/newsInfo/listContainTag",
    method: "get",
    params: query,
  });
}

// 查询新闻分类
export function getNewCategory(query) {
    return request({
      url: "/newsCategory/list",
      method: "get",
      params: query,
    });
  }

 // 根据板块查询新闻
export function getNewByCategory(id) {
    return request({
      url: "/newsCategory/"+id,
      method: "get",
    });
  } 

export function getNewsCategoryList(query) {
    return request({
        url: '/newsCategory/list',
        method: 'get',
        params: query
    })
}


export function getNewsInfo(id) {
    return request({
        url: '/newsInfo/' + parseStrEmpty(id),
        method: 'get'
    })
}