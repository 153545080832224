import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import NewsView from '../views/NewsView.vue'
//新闻详情页面
import NewsInfo from "@/views/newsInfo";
import NewsListByCategory from "@/views/newsListByCategory";


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/index',
    meta:{
      title:"门窗之家"
    }
  },
  {
    path: '/index',
    name: 'index',
    component: HomeView,
    meta:{
      title:"门窗之家 - 您身边的门窗专家！"
    }
  },
  {
    path: '/brand',
    name: 'brand',
    component: () => import('@/views/brand/index.vue'),
  },
  {
    path: '/brand/show',
    name: 'brandShow',
    component: () => import('@/views/brand/brand-show/index.vue'),
  },
  {
    path: '/productSell',
    name: 'productSell',
    component: () => import('@/views/productSell/index.vue'),
    meta:{
      title:"门窗之家 - 您身边的门窗专家！"
    }
  },
  {
    path: '/productSell/show',
    name: 'productShow',
    component: () => import('@/views/productSell/product-show/index.vue'),
    meta:{
      title:"门窗之家 - 您身边的门窗专家！"
    }
  },
  {
    path: '/special',
    name: 'special',
    component: () => import('@/views/special/index.vue'),
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('@/views/NewsView.vue'),
  },
  {
    path: '/top10',
    name: 'top10',
    component: () => import('@/views/top10/index.vue'),
  },
  {
    path: '/column',
    name: 'column',
    component: () => import('@/views/column/index.vue'),
  },
  {
    //新闻详情页面
    path: '/newsInfo',
    name: 'NewsInfo',
    component: () => import('@/views/newsInfo.vue'),
   // component: NewsInfo,
    meta:{
      title:"门窗新闻_新闻详情 - 门窗之家",
      hidden: true,
      meta: {
        auth: false
      }
    }
  },
  {
    //新闻详情页面
    path: '/newsListByCategory',
    name: 'NewsListByCategory',
    component: NewsListByCategory,
    meta:{
      title:"门窗新闻_新闻列表 - 门窗之家",
      hidden: true,
      meta: {
        auth: false
      }
    }
  },
  {
    //展会信息汇总
    path: '/newsListByTopic',
    name: 'NewsListByTopic',
    component: () => import('@/views/newscaterory/newsListByTopic.vue'),
    meta:{
      title:"门窗新闻_新闻列表 - 门窗之家",
      hidden: true,
      meta: {
        auth: false
      }
    }
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('@/views/search/index.vue'),
  },
  {
    path: '/about/index',
    name: 'about',
    component: () => import('@/views/about/index.vue'),
  },
  {
    path: '/about/contact',
    name: 'contact',
    component: () => import('@/views/about/contact.vue'),
  },
  {
    path: '*',
    redirect: '/',
  },
  // },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
