<template>
    <div class="product">
        <div class="itit">
            <div class="bt ititsdpp">{{title}}</div>
        </div>

        <div class="con">
            <div class="item" v-for="(item, index) in productList" :key="index" @click="productShow(item)">
                <img :src="item.titleImgUrl" :alt="item.title" :title="item.title" />

                <div class="name">{{ item.title }}</div>
                <div class="price">面议</div>
            </div>
            
        </div>
    </div>
</template>

<script>
import { getproductInfo } from '@/api/product/product'
export default {
    name: 'product',
    data() {
        return {
            productList: [],
            query:{
                pageNum:1,
                pageSize:20
            }
        }
    },
    props: {
        title: {
            type: String,
            default: '门窗产品',
        },
        productCategoryId:{
            type: Number,
            default: undefined,
        }
        
    },
    mounted() {
        var query = {
            ...this.query,
            productCategoryId:this.productCategoryId
        }
        getproductInfo(query).then((res) => {
            // console.log(222, res)
            this.productList = res.rows
        })
    },
    methods:{
        productShow(item){
             this.$router.push({ name: 'productShow', query: { id: item.id } })
        }
    }
}
</script>

<style scoped lang="scss">
div {
    overflow: initial;
}
.itit {
    border-bottom: #eee 3px solid;
    position: relative;
    height: 50px;
    margin-bottom: 11px;
    overflow: inherit;
    .bt {
        font-size: 24px;
        height: 48px;
        line-height: 48px;
        position: absolute;
        bottom: -3px;
        left: 0px;
        z-index: 2;
    }
}

.ititsdpp {
    background: url('@/assets/image/title/mccp.png') left center no-repeat;
    background-size: auto 26px;
    text-indent: 30px;
    border-bottom: #d4237a 3px solid;
}
.product {
    .con {
        padding: 20px;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        .item {
            cursor: pointer;
            height: 175px;
            width: 125px;
            img {
                height: 125px;
                width: 125px;
                border: 1px solid #eae9e9;
            }
            .name {
                text-align: center;
                color: #666;
                margin-bottom: 3px;
                //  font-size: 12px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
            }
            .price {
                font-size: 12px;
                color: #af4b42;
                text-align: center;
            }
        }
    }
}
</style>