<template>
  <div class="news">
    <div class="title">
      <div class="titleText">家具头条</div>
      <div class="switch"> <a>换一批</a> </div>
    </div>
    
    <div class="content">
        <div class="top">
            <img src="" alt="">
            <div>家具头条家具头家具头条家具头条家条家具头条家条家具头条家条家具头条家条家具头条家具头条家具头条家具头条家具头条家具头条家具头条家具头条家具头条家具头条s</div>
        </div>
        <div class="middle">

            <div class="p" v-for="item in 10" :key="item">屋富球铝业| 第六届中国大定制家居营销发展论坛暨品牌典重磅揭</div>
        </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "News",
};
</script>

<style scoped>
.news {
  background-color: #fff;
  height: 100%;
}
.titleText {
  background: url("@/assets/image/titpic.png") left center no-repeat;
  background-size: auto 1.6vw;
  color: #9A3732;
}
.title {
  padding: 0.5vw;
  font-size: 1.3vw;
  text-indent: 2.3vw;
  border-top: #eee 1px solid;
  border-bottom: #eee 1px solid;
  line-height: 2vw;
  margin-bottom: 0.5vw;
  height: 2vw;
  display: flex;
  justify-content: space-between;
}
.switch{
    font-size: 0.9vw;
}
.switch a{
    color: rgb(111, 166, 188);
}
.switch a:hover{
    color: red;
}
.top{
    /* border: 1px solid red; */
    padding: 0.5vw;
    display: flex;
    cursor:pointer;
    /* height: 4.2vw; */
}
.top img{
    /* border: 1px solid red; */
    width: 9vw;
    height: 7vw;
}
.top div{
    
    line-height: 1.8vw;
    width: 100%;
    height: 100%;
    margin-left: 1vw;
    font-size: 1.1vw;
     overflow: hidden;
    /* border: 1px solid red; */
    text-overflow: ellipsis;
    /* white-space:nowrap; */
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    
}
.top div:hover{
    color: #9A3732;
}
.middle{
    padding: 0.5vw 1vw;
}
.middle .p{
    cursor:pointer;
    margin-bottom: 0.8vw;
    font-size: 1vw;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space:nowrap;
    /* border: 1px solid red; */
}
.middle .p:hover{
    color: #9A3732;
}
/* .top div p{
    
    height: 100%;
} */
</style>