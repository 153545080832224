<template>
  <div class="tabs">
    <div class="m itit">
      <div class="bt ititsdpp">门/窗获奖品牌</div>
      <div class="iprocoount">提供最新最权威的门窗行业十大品牌排行榜单</div>
    </div>

    <div class="m card">
      <el-tabs type="border-card" ref="tabs">
        <el-tab-pane v-for="(item, index) in tabs" :key="index" :label="item.title">
          <prize :mode="item.key == 0 ? 0 : 1" :show-data="dataPrize[item.key]" />

        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import Prize from "@/components/home/Prize";

export default {
  name: "Tabs",
  components: { Prize },
  data() {
    return {
      tabs: [

        { key: 1, title: "别墅门十大领导品牌" },
        { key: 2, title: "别墅门十大品牌" },
        { key: 3, title: "高端定制入户门十大品牌" },
        { key: 4, title: "定制户门十大品牌" },
        { key: 5, title: "高端系统入户门十大品牌" },
        { key: 6, title: "供应链" },
        { key: 0, title: "特别奖" },
      ]
      ,
      dataPrize: [
                [
                {
                        id: 4,
                        bak1: "高端定制入户门行业领军企业",
                        name: "梵蒂斯",
                        logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/63f906bd-71af-4893-8e9f-2c6703c75ef2.jpg"
                    },
                    {
                        id: 3,
                        bak1: "别墅门行业领军企业",
                        name: "熊熊",
                        logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/73ffe899-956e-49e6-8b57-1bd80211eb9b.jpg"
                    },
                    {
                        id: 1,
                        bak1: "高端安全门领导品牌",
                        name: "德盾",
                        logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/aeea3a2d-904e-4534-853d-04b630107a8f.jpg"
                    },
                    {
                        id: 5,
                        bak1: "定制户门行业最受消费者喜爱品牌",
                        name: "金大",
                        logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/7fb76f34-5d1c-44bf-813b-bf9f3dcfbd8d.jpg"
                    },

                    {
                        id: 6,
                        bak1: "高端定制入户门行业先锋设计奖",
                        name: "跃龙门",
                        logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/3a495b3a-9a91-4827-91be-2d3a50112b05.jpg"
                    },
                
                
                    {
                        id: 7,
                        bak1: "精雕铸铝大门首选品牌",
                        name: "精武门",
                        logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/4b03538b-4579-42ea-a3cd-d7ae7ab46b7e.jpg"
                    },
                    {
                        id: 2,
                        bak1: "别墅门最具影响力品牌",
                        name: "百年明牌",
                        logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/e89cb7e8-af84-4b87-bb06-2a1069057bf8.jpg"
                    },
                    {
                        id: 8,
                        bak1: "防褪色表面技术创新领跑者",
                        name: "永佳",
                        logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/fe019698-9058-4504-b52a-60dccbeb7c39.jpg"
                    },
                    {
                        id: 9,
                        bak1: "别墅门行业特别贡献奖",
                        name: "久盛",
                        logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/9d2f7109-2b0a-4816-924d-12c0f966b7f5.jpg"
                    },
                ],
                //别墅门十大领导品牌
                [

                { id: 10, name: "西州艺墅门", logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/a06946c2-6875-4684-9ec6-37413d55efd0.jpg" },
                { id: 8, name: "年年红", logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/1db5f5da-f08e-412e-9eef-6e4dcc856168.jpg" },
                { id: 5, name: "精武门", logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/5304ccc3-5c8e-4be2-b631-5c603f13cc27.jpg" },
                { id: 7, name: "久盛", logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/45ecfbf8-b6a3-444f-a147-43a5c55d9178.jpg" },
                { id: 4, name: "金胜山", logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/b7eff443-4823-4c6f-a9b6-b4a317620872.jpg" },
                { id: 3, name: "金帝豪", logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/8da7cde3-2e1c-4b5d-8415-2b9af1aadedf.jpg" },
                { id: 1, name: "步步升", logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/9119dd93-199f-4ff2-9dd9-6fafd673738f.jpg" },
                { id: 6, name: "久安", logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/535416a8-e3c5-48cd-bc71-6e2067b5bc2d.jpg" },
                { id: 9, name: "沁牌", logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/65870eb4-a68e-4a23-9da4-95326b47054b.jpg" },
                { id: 2, name: "大掌门", logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/f08a125d-1e6d-4466-b3d1-e3bed5570af6.jpg" },
                ],
                //别墅门十大品牌
                [
                { id: 10, name: "西州艺墅门", logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/c906b05b-8f0e-47d3-a9ab-fbab18bbfb21.jpg" },
                { id: 2, name: "步步升", logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/08695d2d-5542-4e5e-9ca9-58d5bb634103.jpg" },
                { id: 8, name: "沁牌", logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/6ea2a06f-2bca-46a4-b41e-505117cebcc2.jpg" },
                { id: 7, name: "年年红", logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/887d6697-55e9-48c2-acb2-ea92ea355f52.jpg" },
                { id: 5, name: "康神", logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/e97f5147-6ae9-4b96-a96f-c2c7f6d88f5a.jpg" },
                { id: 1, name: "艾德乐", logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/b21b6570-98e0-44e3-96c5-dbcd95d10520.jpg" },
                { id: 3, name: "缔康", logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/fa529944-8d14-4807-b474-e79f774886cd.jpg" },
                { id: 6, name: "迈蒂尼", logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/014e8850-3bd7-4c75-a659-6f2fdf54b8ad.jpg" },
                { id: 9, name: "天赐福", logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/5cdd2047-a1ed-43a5-8419-21a18c875508.jpg" },
                { id: 4, name: "东方鸿福", logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/36156545-2970-4cc3-90d1-e25882cd6919.jpg" },
                ]
                ,
                //高端定制入户门十大品牌 1
                [
                { id: 5, name: "凡帝罗", logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/b9ad29fd-c1bb-48e4-a7a8-9e725f998ca5.jpg" },
                { id: 2, name: "爱仕堡", logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/2a657325-9de0-465d-8304-92c28772059e.jpg" },
                { id: 4, name: "法兰帝", logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/62732571-68ef-413e-9367-0dfd0eac2d07.jpg" },
                { id: 7, name: "奢曼", logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/a5e0661a-b7cd-4b5c-b3e7-19ec23c7e3fd.jpg" },
                { id: 10, name: "跃龙门", logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/aeded753-3ddb-40c0-b8b8-4941e633df0e.jpg" },
                { id: 8, name: "艺之帝", logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/3f8e6acd-1d12-4eb8-93c7-8eb783adb8d4.jpg" },
                { id: 6, name: "卡诺嘉", logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/741b327f-2b0f-40cb-8313-17ba5c3dc326.jpg" },
                { id: 3, name: "臣和装甲门", logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/29208077-5052-4441-874b-fadec580a264.jpg" },
                { id: 9, name: "意高", logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/45b64b9f-8a26-4150-8b4e-eb448a3e4e29.jpg" },
                { id: 1, name: "艾德曼", logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/f882d7f2-1aac-4c97-b488-2985c8f4e5dd.jpg" },
                ]
                ,
                //定制户门十大品牌
                [
                { id: 1, name: "帝伦特", logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/5dca9304-2f94-4463-9ad7-5b96e02b81af.jpg" },
                { id: 5, name: "京京", logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/7054cef2-4c2b-4980-96a0-aa4c15af4a3e.jpg" },
                { id: 10, name: "臻能", logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/2bdd669a-2487-4cbe-bfbb-7ae78b0277b9.jpg" },
                { id: 7, name: "索德菲", logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/bafcbfd4-6936-48c8-9e67-a3cd8bf45c02.jpg" },
                { id: 2, name: "福熙", logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/7ad8c8cc-a795-46f1-90ff-3c31d099cd33.jpg" },
                { id: 6, name: "精武门", logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/b71ca930-b7d1-4fc2-931e-f6a9e2c4a80c.jpg" },
                { id: 4, name: "金阳宇", logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/5f2869b7-6989-4c29-b47d-3456ff9b956e.jpg" },
                { id: 8, name: "天赐福", logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/cd913c5b-a785-4da7-b4c5-5df4cc9d2cf4.jpg" },
                { id: 3, name: "金大", logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/e7fee71b-ff16-4d82-8cfa-3c6c0b16b18a.jpg" },
                { id: 9, name: "伊盾", logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/2bb9aad2-14ae-47d5-8c71-51bdaf5c016f.jpg" },
                ]
                ,
                //高端系统入户门十大品牌
                [

                { id: 5, name: "英可纳", logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/06efeac3-168b-480d-a2b7-6001b009b755.jpg" },
                { id: 1, name: "德诺特", logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/72ed0834-9e33-4adc-95dc-ae313b668d32.jpg" },
                { id: 3, name: "卡诺嘉", logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/a3d09744-0299-4c10-8fa3-24002d7b21f7.jpg" },
                { id: 6, name: "跃龙门", logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/546430a0-59a8-46a9-a174-e0d5004e4df8.jpg" },
                { id: 4, name: "醒石", logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/e2723f81-7a52-4edd-93c1-697e7d16617e.jpg" },
                { id: 2, name: "梵赫", logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/af2e392b-4027-43c3-9b0c-01749cc5ebd9.jpg" },
                ]
                ,
                //供应链
                [
                    { id: 1, name: "飞宇门花", logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/b24957e7-9de3-4a21-b9c4-b66f7032cfe8.jpg" },
                    { id: 6, name: "毅力铰链", logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/86b39d40-4159-46ca-9cb6-4947606f95b4.jpg" },
                    { id: 2, name: "恒泰智能", logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/8b953fcb-2fa3-410a-b0b8-c558f92ebfe4.jpg" },
                    { id: 5, name: "雅尊门面", logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/0b267476-fcbd-4628-9687-add1965932f4.jpg" },
                    { id: 3, name: "迈门网", logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/3539051d-e91c-42e5-a1e2-e2d2d6d82846.jpg" },
                    { id: 4, name: "品承", logoAddressUrl: "https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-07-10/6426a4e1-3c78-41e3-a32e-cbd29812cc7a.jpg" },
                ]
                ,

            ],
    }
  },
  methods: {
    mouseenter(key) {
      console.log(this.$refs.tabs.currentName)
      // this.$refs.tabs.currentName=key
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.tabs.$refs.nav.$nextTick(() => {
        // 此时tab的nav才渲染dom 否则拿不到el-tabs__item
        var target = document.getElementsByClassName("el-tabs__item");
        let that = this;
        for (let i = 0; i < target.length; i++) {
          target[i].addEventListener("mouseover", () => {
            that.$refs.tabs.handleTabClick(1, String(i));
          });
        }
      });
    });
  },

};
</script>

<style scoped lang="scss">
div {
  overflow: initial;
}

.tabs {
  /* border: 1px solid red; */
  /* clear: both; */
}

.ad {
  //  border: 1px solid red;
  height: 60px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  .img {

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: white;
    font-weight: 600;
    background-color: #B80D0D;
    font-size: 24px;
  }
}

.itit {
  border-bottom: #eee 3px solid;
  position: relative;
  height: 50px;
  margin-bottom: 0.7vw;
  /* overflow: inherit */
}

.itit .bt {
  font-size: 24px;
  height: 48px;
  line-height: 48px;
  position: absolute;
  bottom: -3px;
  left: 0px;
  z-index: 2;
}

.ititsdpp {
  background: url("@/assets/image/titsdpp.png") left center no-repeat;
  background-size: auto 26px;
  text-indent: 30px;
  border-bottom: #d4237a 3px solid;
}

.itit .iprocoount {
  left: 230px;
  font-size: 14px;
  color: #999;
  height: 30px;
  line-height: 30px;
  position: absolute;
  top: 16px;
  background: url("@/assets/image/ijoincount.png") left center no-repeat;
  text-indent: 22px;
}

/deep/ .el-tabs--border-card>.el-tabs__header {
  /* background-color: white; */
  border-bottom: 0;
}

/deep/ .el-tabs__item {
  overflow: initial;

}

/deep/ .el-tabs--border-card>.el-tabs__header .el-tabs__item:not(.is-disabled):hover {
  color: #9a3732;
  font-weight: 800;
}

/deep/ .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
  color: #9a3732;
  font-weight: 800;
}

.card {
  padding-bottom: 2vw;

  img {
    // background-color: red;
    // width: 100%;
    // height: 100%;
  }
}</style>