<template>
    <div class="newsview">
        <div class="m">
            <el-row :gutter="20" class="topRow">
                <el-col :span="16">
                    <Carousel height="330px" />
                </el-col>

                <el-col :span="8">
                    <NewsMod height="330px" :mode="0" title="热点新闻" :data="topNewsData.rows" v-show="topNewsData.rows.length" @switch="switchFun('topNewsData')"></NewsMod>
                </el-col>
            </el-row>

            <el-row :gutter="20">
                <el-col :span="16">
                    <div class="content">
                        <NewsNav />
                    </div>
                </el-col>

                <el-col :span="8">
                    <div class="content">
                        <NewsMod :mode="0" title="点击排行" :data="hitsNewsData.rows" v-show="hitsNewsData.rows.length" @switch="switchFun('hitsNewsData')" />
                        <div class="h20"></div>
                        <NewsMod :mode="0" title="评论排行" :data="hitsNewsData.rows" v-show="hitsNewsData.rows.length" @switch="switchFun('hitsNewsData')" />
                        <!--          <div class="h20"></div>-->
                        <!--          <NewsMod :mode="2" title="评论排行"/>-->
                        <!--          <div class="h20"></div>-->
                        <!--          <NewsMod :mode="3" title="评论排行"/>-->
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import Carousel from '@/components/home/banner/Carousel.vue'
import NewsMod from '@/components/news/NewsMod.vue'
import NewsNav from '@/components/news/NewsNav.vue'
import { getNewList } from '@/api/news/news.js'

export default {
    name: 'NewsView',
    components: {
        Carousel,
        NewsMod,
        NewsNav,
    },
    data() {
        return {
            //热点新闻
            topNewsData: [],
            topNewsDataQuery: {
                pageNum: 1,
                pageSize: 10,
                level: 1,
            },
            //点击排行
            hitsNewsData: [],
            hitsNewsDataQuery: {
                pageNum: 1,
                pageSize: 10,
                hits: 1,
            },
            //评论排行
            commentNewsData: undefined,
        }
    },
    created() {
        //热点新闻
        this.getNewList(this.topNewsDataQuery, 'topNewsData')
        //点击排行
        this.getNewList(this.hitsNewsDataQuery, 'hitsNewsData')
    },

    methods: {
        switchFun(list) {
            
             if (this[list+"Query"].pageNum * this[list+"Query"].pageSize < this[list].total) {
                this.$set(this[list+"Query"], 'pageNum', this[list+"Query"].pageNum + 1)
            } else {
                this.$set(this[list+"Query"], 'pageNum', 1)
            }

            this.getNewList(this[list+"Query"],list)
        },
        getNewList(quary, list) {
            getNewList(quary).then((response) => {
                this[list] = response
                this[list+"Total"] = response.total
                //  console.log("点击排行",this.topNewsData);
            })
        },
    },
}
</script>

<style scoped lang="scss">
div {
    overflow: initial;
}

.newsview {
    /* width: 1920px; */
    padding: 20px 0;
    background-color: #eeeeee;
    .m {
        background-color: #eeeeee;
        // border: 1px solid red;
        overflow: hidden;
    }
    .topRow {
        // border: 1px solid red;
        margin-bottom: 20px;
        overflow: initial;
    }
}
</style>
