import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { getDicts } from "@/api/system/dict/data";

//管理页面meat
import MetaInfo from 'vue-meta-info'
Vue.use(MetaInfo)

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { parseTime, resetForm, addDateRange, selectDictLabel, selectDictLabels, handleTree } from "@/utils/aoyin"

import Pagination from "@/components/Pagination";
// 字典数据组件
import DictData from '@/components/DictData'
// 字典标签组件
import DictTag from '@/components/DictTag'

// 全局方法挂载
Vue.prototype.getDicts = getDicts
Vue.prototype.parseTime = parseTime
Vue.prototype.resetForm = resetForm
Vue.prototype.addDateRange = addDateRange
Vue.prototype.selectDictLabel = selectDictLabel
Vue.prototype.selectDictLabels = selectDictLabels
Vue.prototype.handleTree = handleTree

Vue.config.productionTip = false

Vue.component('DictTag', DictTag)
Vue.component('Pagination', Pagination)
Vue.use(ElementUI);

// 相对时间过滤器,把时间戳转换成时间
// 格式: 2020-02-25 21:43:23
import dayjs from './assets/js/dayjs.min.js'
DictData.install()
Vue.filter('dateFormat', (dataStr) => {
  var str='YYYY-MM-DD'
  // HH:mm:ss
  return dayjs(dataStr).format(str)
});



// router.beforeEach((to, from, next) => {
//   /* 路由发生变化修改页面title */
//   console.log(111,to,from)
//   if(to.fullPath==from.fullPath){
//     return
//   }
//   next()
// })

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
