import request from '@/utils/request'
import { parseStrEmpty } from "@/utils/aoyin";

// 查询用户列表
export function list(query) {
  return request({
    url: '/joinInfo/list',
    method: 'get',
    params: query
  })
}

// 查询用户详细
export function getInfo(id) {
  return request({
    url: '/joinInfo/' + parseStrEmpty(id),
    method: 'get',
  })
}

// 新增用户
export function add(data) {
  return request({
    url: '/joinInfo',
    method: 'post',
    data: data
  })
}

