<template>
  <div class="content">
    <div class="video">
      <div class="left">
        <Carousel :show="false" />
      </div>
      <div class="right">

        <div class="rightVideo">
         <Videos>
              <div class="title">
                <div style="border-left:0.5vw solid #e7971e;text-indent: 1vw;">展会直通车</div>
              <!-- <div class="titleText">展会直通车</div> -->
              
            </div>
            </Videos>
        </div> 

      </div>
    </div>
    <div class="m">
      <el-row>
        <el-col :span="16"
          ><div class="grid grid-left">
            
            <Videos>
              <div class="title">
              <div class="titleText">热点视频</div>
              <i>
                <a
                  href="##"
                  title="展会直通车"
                  >展会直通车</a
                >
                &nbsp;&nbsp;/&nbsp;&nbsp;<a
                  href="##"
                  title="人物专访"
                  >人物专访</a
                >
                &nbsp;&nbsp;/&nbsp;&nbsp;<a
                  href="##"
                  title="企业实力"
                  >企业实力</a
                >
                &nbsp;&nbsp;/&nbsp;&nbsp;<a
                  href="##"
                  title="产品视频"
                  >产品视频</a
                >
                &nbsp;&nbsp;/&nbsp;&nbsp;<a
                  href="##"
                  title="品牌宣传"
                  >品牌宣传</a
                >
                &nbsp;&nbsp;/&nbsp;&nbsp;<a
                  href="##"
                  title="经验交流"
                  >经验交流</a
                >
              </i>
            </div>
            </Videos>
            </div
        ></el-col>
        <el-col :span="8"><div class="grid grid-right">
          
          <News/>
          
          </div></el-col>
      </el-row>
    </div>

    <div class="m ad">
      <div class="aaa"><h1>广告位</h1></div>
      <div class="aaa"><h1>广告位</h1></div>
      <div class="aaa"><h1>广告位</h1></div>
    </div>
  </div>
</template>

<script>
import Carousel from "../banner/Carousel.vue";
import News from "./News.vue";
import Videos from "./Videos.vue";

export default {
  name: "Content",
  components: {
    News,
    Carousel,
    Videos,
  },
};
</script>

<style scoped>
.rightVideo{
  border: 1px solid red;
  height: 85%;
  padding: 3vw;
  /* ov */
}
.titleText {
  background: url("@/assets/image/titpic.png") left center no-repeat;
  background-size: auto 1.6vw;
}
.title {
  padding: 0.5vw;
  font-size: 1.3vw;
  text-indent: 2.3vw;
  border-top: #eee 1px solid;
  border-bottom: #eee 1px solid;
  line-height: 2vw;
  margin-bottom: 0.5vw;
  height: 2vw;
  display: flex;
  justify-content: space-between;
}
.title i {
  font-size: 1vw;
  color: #999;
}
.title i a {
  color: #999;
}

.title i a:hover {
  color: #ff6600;
}
.video {
  /* border: 1px solid red; */
  height: 40vw;
  margin-bottom: 3vw;
  display: flex;
  /* e7971e */
  /* c8bbb2 */
}
.left {
  /* display: inline-block; */
  height: 100%;
  width: 50%;
  /* border: 1px solid red; */
  /*background-color: #c8bbb2;*/
  background-color: white;
}
.right {
  /* display: inline-block; */
  height: 100%;
  width: 50%;
  /* border: 1px solid red; */
  background-color: #e7971e;
  /* padding: 1vw; */
}
.grid {
  /* border: 1px solid red; */
  height: 33vw;
  background-color: #eeeeee;
}
.grid-left {
  padding: 0.3vw;
}
.grid-right {
  padding: 0.3vw;
}
.gridBox {
  height: 15vw;
}
.ad {
  /* border: 1px solid red; */
  margin-top: 3vw;
  margin-bottom: 1.5vw;
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: space-around;
}
.aaa {
  border: 1px solid red;
  width: 25vw;
  height: 7vw;
  background-color: #e7971e;
  text-align: center;
  line-height: 7vw;
}
</style>