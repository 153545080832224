<template>
  <div id="root">
    <!-- <div class="float">
        <Float />
    </div> -->
    

    <Head />
    <!-- <div class="m h20"></div> -->
    <Search />
    <Nav />
    <router-view></router-view>

    <Footer/>
  </div>
</template>
<script>
import Head from "./components/Head.vue";
import Search from "./components/Search.vue";
import Nav from "./components/Nav.vue";
import Float from "./components/Float.vue";
import Footer from "./components/Footer.vue";
export default {
  components: {
    Head,
    Search,
    Nav,
    Float,
    Footer
  },
 
};
</script>

<style lang="scss">
@import "@/assets/styles/index.scss";
#root {
  overflow: initial;
}
.float{
   position:fixed;
   right:0;
   bottom:50px;
  z-index:99;
  background-color:#fcddbf;
}
</style>
