<template>
    <div class="newsInfo">
        <div class="m">
            <div class="breadcrumb">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item :to="{ path: '/news' }">新闻资讯</el-breadcrumb-item>
                    <el-breadcrumb-item :to="{path:'/newsListByCategory',query:{newsCategoryId: entity.newsCategoryId}}">{{newsCategoryFormat(entity.newsCategoryId)}}</el-breadcrumb-item>
                    <el-breadcrumb-item>新闻详情</el-breadcrumb-item>
                </el-breadcrumb>
            </div>

            <el-row :gutter="20">
                <el-col :span="16">
                    <div class="con">
                        <div class="title">{{ entity.title }}</div>
                        <div class="showInfo">
                            <span>日期：{{ parseTime(entity.createTime) }}</span>
                            <span>浏览次数：{{ entity.hits }}</span>
                            <span>评论数: 0</span>
                        </div>

                        <div class="show content">
                            <div class="html content">
                                <div v-html="entity.content" class="article"></div>
                            </div>
                        </div>

                        <NewsMod :mode="4" title="同类新闻" :data="similiarNewsData" v-show="similarNewsShow" height="170px"></NewsMod>
                    </div>
                </el-col>
                <el-col :span="8">
                    <NewsMod height="330px" :mode="0" title="热点新闻" :data="topNewsData.rows" v-show="topNewsData.rows.length" @switch="switchFun('topNewsData')"></NewsMod>

                    <!-- <div class="h20"></div> -->
                        <NewsMod :mode="0" title="点击排行" :data="hitsNewsData.rows" v-show="hitsNewsData.rows.length" @switch="switchFun('hitsNewsData')" />
                    <!-- <div class="h20"></div> -->
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import { getNewsInfo, getNewList, getSimiliarNewList } from '@/api/news/news.js'
import NewsMod from '@/components/news/NewsMod.vue'

export default {
    name: 'NewsInfo',
    dicts: ['news_category_info'],
    components: {
        NewsMod,
    },
    data() {
        return {
            entity: {
                title: '门窗之家-门窗新闻',
            },
            id: undefined,
            show: undefined,
            //热点新闻
            topNewsData: [],
            topNewsDataQuery: {
                pageNum: 1,
                pageSize: 10,
                level: 1,
            },
            //点击排行
            hitsNewsData: [],
            hitsNewsDataQuery: {
                pageNum: 1,
                pageSize: 10,
                hits: 1,
            },
            //评论排行
            commentNewsData: undefined,
            commentNewsShow: false,

            //同类新闻
            similiarNewsData: undefined,
            similarNewsShow: false,
        }
    },
    metaInfo() {
        return {
            title: this.entity.title,
        }
    },
    created() {
        this.show = false

        this.id = this.$route.query.id
        if (this._isMobile()) {
            window.location.href = 'https://m.cnnmumen.com/pages/news/show?id=' + this.id
        }
        //热点新闻
        this.getNewList(this.topNewsDataQuery, 'topNewsData')
        //点击排行
        this.getNewList(this.hitsNewsDataQuery, 'hitsNewsData')

    },
    mounted() {
        this.id = this.$route.query.id
        if (this._isMobile()) {
            window.location.href = 'https://m.cnnmumen.com/pages/news/show?id=' + this.id
        }

        getNewsInfo(this.id).then((response) => {
            this.entity = response.data
            this.getSimiliarNewListM();
        })
       
    },
    //监听器
    watch: {
        // 方法1
        $route(to, from) {
            //监听路由是否变化
            //  console.log(to,from)
            // if (to.path == "/") { //跳转到哪个页面
            //   location.reload()
            // }
            if (to.query.id !== from.query.id) {
                this.id = to.query.id
                this.show = false
                getNewsInfo(this.id).then((response) => {
                    this.entity = response.data
                    this.show = true
                    this.getSimiliarNewListM()
                })
            }
        },
    },
    methods: {
        getSimiliarNewListM() {
            //同类新闻
            var query={
                tag:this.entity.tag
            };
            getSimiliarNewList(query).then((response) => {
                // console.log("this.topNewsData");
                this.similiarNewsData = response.data
                this.similarNewsShow = true
                console.log('同类', this.similiarNewsData)
                // console.log("this.topNewsData");
            })
        },
        newsCategoryFormat(id) {
            return this.selectDictLabel(this.dict.type.news_category_info, id)
        },
        _isMobile() {
            let flag = navigator.userAgent.match(
                /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
            )
            return flag
        },
        switchFun(list) {
            
             if (this[list+"Query"].pageNum * this[list+"Query"].pageSize < this[list].total) {
                this.$set(this[list+"Query"], 'pageNum', this[list+"Query"].pageNum + 1)
            } else {
                this.$set(this[list+"Query"], 'pageNum', 1)
            }

            this.getNewList(this[list+"Query"],list)
        },
        getNewList(quary, list) {
            getNewList(quary).then((response) => {
                this[list] = response
                this[list+"Total"] = response.total
                //  console.log("点击排行",this.topNewsData);
            })
        },
    },
}
</script>

<style scoped lang="scss">
div {
    overflow: initial;
}

.newsInfo {
    /deep/ .el-breadcrumb {
        .el-breadcrumb__item {
            .is-link {
                &:hover {
                    color: #af4b42;
                }
            }
        }
    }

    .breadcrumb {
        padding: 10px 0;
        border-bottom: 1px solid #d8d8d8;
        margin-bottom: 20px;
    }

    .con {
        .title {
            padding-bottom: 20px;
            font-size: 26px;
            line-height: 32px;
            font-weight: normal;
        }

        .showInfo {
            color: #999;
            border-top: #eee 1px solid;
            border-bottom: #eee 1px solid;
            // height: 32px;
            // line-height: 32px;
            padding: 10px 0;
            display: flex;
            gap: 20px;
        }

        .show {
            // border: 1px solid red;
            .html {
                .article {
                    ::v-deep img {
                        max-width: 750px;
                    }
                }
            }
        }

        .content {
            overflow: hidden;
            font-size: 16px;
            color: #666;
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }
}
</style>