<template>
    <div class="hotVideos">
        <div class="itit">
            <div class="bt ititsdpp">热点视频</div>
        </div>

        <div class="con">
            <div class="first" v-if="videos.length>0" @click="playVideo(videos[0])">
                <!-- <video>
                    <source :src="videos[0].videoUrl" type="video/mp4" />
                </video>-->
                <div class="videoImg">
                    <img :src="videos[0].titleImgUrl" alt />
                </div>
                <div class="play" ></div>
                <div class="title">{{ videos[0].title }}</div>
            </div>

            <div class="videos" v-if="videos.length>1">
                <div class="videos__item" v-for="(item, index) in videos.slice(1,7)" :key="index" @click="playVideo(item)">
                    <!-- <video>
                            <source :src="item.videoUrl" type="video/mp4" />
                    </video>-->
                    <div class="videoImg">
                        <img :src="item.titleImgUrl" alt />
                    </div>
                    <div class="play" ></div>
                    <div class="title">{{ item.title }}</div>
                </div>
            </div>
        </div>

        <el-dialog :title="videoTitle" :visible.sync="dialogVisible" width="70%" v-if="dialogVisible">
            <div class="video" style="height: 600px">
                <video autoplay="autoplay" controls="controls" style="height: 100%; width: 100%; object-fit: contain">
                    <source :src="videoUrl" type="video/mp4" />
                </video>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { getVideos } from '@/api/video/video'
export default {
    name: 'hotVideos',
    data() {
        return {
            dialogVisible: false,
            videos: [],
            videoUrl: '',
            videoTitle: '',
        }
    },
    mounted() {
        getVideos().then((res) => {
            console.log(111, res)
            this.videos = res.rows
        })
    },
    methods: {
        playVideo(item) {
            console.log(item)
            this.dialogVisible = true
            this.videoUrl = item.videoUrl
            this.videoTitle = item.title
        },
    },
}
</script>

<style scoped lang="scss">
.hotVideos {
    overflow: inherit;
}
.itit {
    border-bottom: #eee 3px solid;
    position: relative;
    height: 50px;
    margin-bottom: 11px;
    overflow: inherit;
    .bt {
        font-size: 24px;
        height: 48px;
        line-height: 48px;
        position: absolute;
        bottom: -3px;
        left: 0px;
        z-index: 2;
    }
}

.ititsdpp {
    background: url('@/assets/image/title/rdsp.png') left center no-repeat;
    background-size: auto 26px;
    text-indent: 30px;
    border-bottom: #d4237a 3px solid;
}

.con {
    gap: 10px;
    // border: 1px solid red;
    padding: 20px;
    display: flex;
    .videoImg{
      // border: 1px solid red;
      height: 100%;
      width: 100%;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .play {
        width: 100px;
        height: 100px;
        border-radius: 2%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-25%, -75%);
        cursor: pointer;
        // background-color: #999;
        // opacity: 0.5;

        //  display: none;
        width: 0px;
        height: 0px;
        // margin: auto;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        border-left: 30px solid #999;
        border-right: 20px solid transparent;
    }
    .title {
        box-sizing: border-box;
        padding: 10px;
        color: white;
        background-color: black;
        opacity: 0.8;
        bottom: 0;
        width: 100%;
        height: 40px;
        // border-radius: 2%;
        position: absolute;
    }
    .first {
        background-color: #999;
        width: 40%;
        height: 300px;
        // padding: 20px;
        box-sizing: border-box;
        position: relative;
        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .videos {
        height: 300px;
        // overflow: scroll;
        // border: 1px solid red;
        width: 60%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        justify-content: space-between;
        gap: 10px;
        // height: 100%;
        &__item {
            position: relative;
            width: 100%;
            height: 150px;
            // border: 1px solid red;
            video {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}
</style>