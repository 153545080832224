<template>

  <div>
    <div
      class="joinform fl fudong"
    >
      <div class="joinformtit">
        <img
          width="90%"
          src="@/assets/image/joinformtit.png"
        />

        <div class="inputBox">
          <el-row :gutter="10">
            <el-col :span="12">
              <el-input
                v-model="input"
                placeholder="请输入内容"
                size="small"
                class="input"
              ></el-input>
            </el-col>
            <el-col :span="12">
              <el-select v-model="value" placeholder="请选择" size="small">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>

          <el-input
            v-model="input"
            placeholder="请输入内容"
            size="small"
          ></el-input>

          <el-row :gutter="10">
            <el-col :span="12">
              <el-select v-model="value" placeholder="请选择" size="small">
                <el-option
                  v-for="item in 24"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="12"> </el-col>
          </el-row>
          
           <el-button type="primary" class="button" size="small">立即提交，离财富更进一步</el-button>
        </div>
      </div>
    </div>
    
    
    
  </div>
</template>


<script>
export default {
  name: "Head",
  props: {
    show: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
      ],
      value: "",
      input: "",
    };
  },
  
};
</script>

<style scoped>
.a{
  height: 100%;
  border: 1px solid red;
}
.joinformtit {
  text-align: center;
  width: 15vw;
}
.inputBox {
  display: flex;
  flex-wrap: wrap;
  align-content:space-between;
  height: 100%;
  /* justify-content: space-between; */
  /* padding: 0.4vw; */
  border: 1px solid red;
  /* width: 15vw; */
  /* height: 10vw; */
}
.fudong {
  padding: 1vw;
}
.button{
    /* margin-top: 0.5vw; */
    border: 0;
    background-color: rgb(243, 78, 78);
    width: 100%;
}
.button:hover{
    background-color: rgb(192, 95, 95);
}
.button:focus{
    background-color: rgb(222, 87, 87);
}
</style>