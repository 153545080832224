<template>
  <div class="banner">
    <div class="m">

      <el-row :gutter="20">
        <el-col :span="24"> <Carousel height="530px"/></el-col>
        <!-- <el-col :span="8"> <Join height="330px"/></el-col> -->

      </el-row>

     
     
    </div>
    <!-- <div class="banner"> -->
    <!-- <div class="m h20"></div>
      <div class="m h20"></div>
      <div class="m h20"></div> -->
  </div>
</template>

<script>
import Carousel from "./Carousel.vue";
import Join from "./Join.vue";
export default {
  name: "Banner",
  components: {
    Carousel,
    Join,
  },
};
</script>

<style lang="scss" scoped>
.banner {
  .m {
    // border: 1px solid red;
    padding: 30px 0;
    // height: 380px;
  }
}
.bannerBox {
  overflow: initial;
  /* border: 1px solid red; */
  height: 83vw;
  .banner {
    /* border: 1px solid red; */
    overflow: initial;
    width: 100%;
    height: 52vw;
    // background: url("@/assets/image/banner.jpg") no-repeat center;
    background-size: cover;
  }
}

.box {
  padding: 3vw;
  /* display: grid;
  grid-template-columns: auto auto auto;
  justify-content: space-between; */
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: inherit;
  /* border: 1px solid red; */
}

.aaa {
  border: 1px red solid;
  width: 23vw;
  height: 14vw;
  margin-bottom: 2vw;
  background-color: #9a3732;
}
.bbb {
  border: 1px red solid;
  width: 23vw;
  height: 7vw;
  margin-bottom: 2vw;
  background-color: #f7941c;
}
.ccc {
  border: 1px red solid;
  width: 100%;
  height: 7vw;
  background-color: #f7941c;
}
</style>