<template>
  <div class="newsnav">
    <el-tabs type="border-card" v-show="show===newsCatLength">

      <el-tab-pane label="最新动态">

        <div v-for="item in list" :key="item.id">
          <router-link :to="{path:'/newsInfo',query:{id: item.id}}">
            <div class="navContent">

              <img :src="item.titleImgUrl" alt="">
              <div class="right">

                <div class="contentTop">
                  <h3>{{ item.title }}</h3>
                  <p>{{ item.introduction }}</p>
                </div>
                <div class="contentBottom">
                  <!--              <div class="slot"><a href="">千鼎木门</a>&nbsp&nbsp&nbsp|&nbsp&nbsp<a href="">千鼎木门加盟</a></div>-->
                  <div class="date">{{ parseTime(item.createTime) }}</div>
                </div>

              </div>

            </div>
          </router-link>
        </div>
      </el-tab-pane>


      <el-tab-pane :label="itemNewsCat.name" v-for="(itemNewsCat,index) in newsCategoryList" :key="itemNewsCat.id">

        <div v-for="item in getNewListByCategory(itemNewsCat.id)" :key="item.id">
          <router-link :to="{path:'/newsInfo',query:{id: item.id}}">
            <div class="navContent">
              <img :src="item.titleImgUrl" alt="">
              <div class="right">

                <div class="contentTop">
                  <h3>{{ item.title }}</h3>
                  <p>{{ item.introduction }}</p>
                </div>
                <div class="contentBottom">
                  <!--              <div class="slot"><a href="">千鼎木门</a>&nbsp&nbsp&nbsp|&nbsp&nbsp<a href="">千鼎木门加盟</a></div>-->
                  <div class="date">{{ parseTime(item.createTime) }}</div>
                </div>

              </div>
            </div>
          </router-link>
        </div>
        <div style="text-align: center;font-size: 1.3vw;" @click="toMore(itemNewsCat.id)">查看更多~~~</div>

      </el-tab-pane>


    </el-tabs>
  </div>
</template>

<script>
import {getNewList, getNewsCategoryList} from "@/api/news/news.js";

export default {
  name: "NewsNav",

  created() {

    getNewList({pageNum: 1, pageSize: 10}).then(response => {
          this.list = response.rows;
          this.total = response.total;
          // console.log(this.list, this.total);
          // this.newsListByCategory.set(0, this.list);
          // this.loading = false;
        }
    );

    getNewsCategoryList().then(response => {
          this.newsCategoryList = response.data;
          this.newsCatLength = this.newsCategoryList.length;
          console.log("分类", this.newsCategoryList, this.newsCategoryList.length);
          //获取每个分类的新闻

          for (let i = 0; i < this.newsCatLength; i++) {
            //this.show.set(this.newsCategoryList[i].id, false);
            getNewList({pageNum: 1, pageSize: 10, newsCategoryId: this.newsCategoryList[i].id}).then(response => {
                  // console.log(i, this.newsCategoryList[i].id);
                  this.listTmp = response.rows;
                  this.newsListByCategory.set(this.newsCategoryList[i].id, this.listTmp);
                  // this.show.set(this.newsCategoryList[i].id, true);
                  this.show++;
                }
            );

          }

        }
    );

  },

  mounted() {


  },
  methods: {
    toMore(id){
   // :to="{path:'/newsListByCategory',query:{newsCategoryId: itemNewsCat.id}}"
      this.$router.push({path:'/newsListByCategory',query:{newsCategoryId: id}});
    },
    getNewListByCategory(id) {
      // console.log("获取", id, this.newsListByCategory.get(parseInt(id)), this.show.get(parseInt(id)));
      return this.newsListByCategory.get(id);
    }
  },
  data() {
    return {
      time: 1670814040,
      newsCategoryList: undefined,
      newsCatLength: undefined,
      newsListByCategory: new Map(),
      show: 0,
      listTmp: undefined,
      totalTmp: undefined,
      list: undefined,
      total: undefined,

    }
  },
};
</script>

<style scoped lang="scss">
.contentBottom .date {
  color: #909399;
}

.contentBottom .slot, a {

  color: #4591e5;
}

.contentTop {
  /* border: 1px solid red; */
  height: 5.95vw;
}

.contentTop p {
  font-size: 0.9vw;
  color: #909399;
  line-height: 1.5vw;
  overflow: hidden;
  /* border: 1px solid red; */
  text-overflow: ellipsis;
  /* white-space:nowrap; */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.contentTop h3 {
  font-size: 1.3vw;
  font-weight: normal;
  margin-bottom: 0.2vw;
}

.contentBottom {
  font-size: 0.9vw;
  /* border: 1px solid red; */
  height: 1.05vw;
  display: flex;
  justify-content: space-between;
  line-height: 1.05vw;
  /* line-height: 150%; */
}

.right {
  /* border: 1px solid red; */
  width: 100%;
  margin-left: 1vw;
}

.navContent img {
  width: 9vw;
  height: 7vw;
}

.navContent {
  display: flex;
  border-bottom: 2px solid #eeeeee;
  padding: 1vw 0;
}

/deep/ .el-tabs__item {
  /* border: 0; */
  overflow: initial;
  width: 100%;
  text-align: center;
  /* color: ; */
}

/deep/ .el-tabs--border-card > .el-tabs__header {
  background-color: #eeeeee;
  border: 0;
}

/deep/ .el-tabs--border-card {
  /* border-bottom:0; */
  /* border-left:0; */
  /* border-right:0; */
}

/deep/ .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  color: red;
  /* font-weight: 800; */
  /* border: 0; */
  border: 1px solid #eeee;
}

/deep/ .el-tabs--border-card > .el-tabs__header .el-tabs__item:not(.is-disabled):hover {
  color: red;
  /* border: #eeeeee; */
  /* font-weight: 800; */
  border: 1px solid #eeee;
}

/deep/ .el-tabs__nav {
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  // grid-template-columns: 25% 25% 25% 25%;
  width: 100%;
}

/* /deep/ .el-tabs__content{
    border-right: 1px solid #DCDFE6;
} */
</style>