<template>
    <div class="prize">
        <div class="mode0" v-if="mode===0">
            <div class="mode0box" v-for="item in showData" :key="item.id">
                <div class="prizeName" :class="item.bak1 !='别墅门最具影响力品牌'? '':'tooLong'">{{item.bak1}}</div>
                <div class="showBox">
                    <img class="img" :src="item.logoAddressUrl" />
                    <div class="brandName">{{item.name}}</div>
                </div>
            </div>
            <div class="clear"></div>
        </div>
        <div class="mode1" v-if="mode ===1">
            <div class="mode1box" v-for="item in showData" :key="item.id">
                <div class="showBox">
                    <img class="img" :src="item.logoAddressUrl" />
                    <div class="brandName">{{item.name}}</div>
                </div>
            </div>
            <div class="clear"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Prize',
    props: {
        showData: [],
        //特别奖项带奖项说明 其他不带   0，为特别奖项    1，为十大奖项
        mode: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {}
    },
}
</script>

<style scoped lang="scss">
.clear {
    clear: both;
}
.prize {
    /*background-color:#FBE4C8;*/
    /*height: 480px;*/
    .mode0 {
      // border: 1px solid red;
      display: flex;
     
      flex-wrap: wrap;
        .mode0box {
            margin: 15px;
            .prizeName{
                height: 30px;
               font-size: 13px;
            }
        }
    }
}

.mode1box {
    float: left;
    margin: 15px;
}
.prizeName {
    width: 200px;
    // height: 100px;
    // border: 1px solid red;
    background-color: #fff;
    text-align: center;
    font-size: 20px;
}
.tooLong{
  font-size: 19px;
}
.showBox {
    width: 200px;
    height: 170px;
    margin-top: 20px;
    background-color: #fff;
    text-align: center;
}
.brandName {
    margin-top: 10px;
    height: 40px;
    font-size: 20px;
}
img {
    width: 198px;
    height: 110px;
    border: solid 1px;
}
</style>