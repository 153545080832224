<template>
  <div class="carousel">
    <div class="carousel__con">
      <el-carousel :height="height" ref="child" :autoplay="autoplay">
        <el-carousel-item v-for="(item, index) in adList" :key="index" @click.native="gotoEach(index)">
          <h1 ><img :src="item.imgUrl" :height="height"/></h1>
        </el-carousel-item>
      </el-carousel>
    </div>

    <!-- <div class="m" v-if="show">

      <el-row :gutter="20">
        <el-col :span="8"
        ><div class="grid-left">
          <div class="brandBox">
            <div
                class="brand"
                v-for="item in ads"
                :key="item.id"
                @mouseenter="mouseenter(item)"
                @mouseleave="mouseleave()"
            >
              <h1><img :src="item.titleImgUrl" height="60px" width="180px"/></h1>
            </div>
          </div>
        </div></el-col
        >

        <el-col :span="16"
        ><div class="bg-purple">
          <div class="block">
            <el-carousel height="35vw" ref="child" :autoplay="autoplay">
              <el-carousel-item v-for="itemM in ads" :key="itemM.id">
                <h1><img :src="itemM.bigImgUrl"/></h1>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div></el-col
        >
      </el-row>
    </div>

    <div class="bg-purple" v-if="!show" >

      <div class="block">
        <el-carousel :height="height" ref="child" :autoplay="autoplay" :indicator-position="dor">
          <el-carousel-item v-for="itemB in ads" :key="itemB.id">
            <h1><img :src="itemB.bigImgUrl"/></h1>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div> -->
  </div>
</template>

<script>
import { getAd } from "@/api/ad/ad";
export default {
  name: "Carousel",
  data() {
    return {
      autoplay: true,
      adList: [],
    };
  },
  props: {
    height: {
      type: String,
      default: "380px",
    },

    show: {
      type: Boolean,
      default: true,
    },
    dor: {
      type: String,
      default: "none",
    },

    ads: {
      //也可能是json格式 xx.imgUrl
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  mounted() {
    var query = {
      pageNum: 1,
      pageSize: 100,
      type: "首页大广告位1",
    };
    getAd(query).then((res) => {
      this.adList = res.rows;
    });
  },
  methods: {
    mouseenter(item) {
      // this.activeIndex=item
      this.autoplay = false;
      this.$refs.child.activeIndex = item - 1;
    },
    mouseleave() {
      this.autoplay = true;
    },
    gotoEach(index){
      console.log("点击"+index)
      window.location.href = this.adList[index].linkUrl
    },
  },
};
</script>

<style scoped lang="scss">
.carousel {
  &__con {
    background-color: darkgray;

    h1 {
      img {
        // border: 1px solid red;
        // height: 100px;
        width: 100%;
        object-fit: cover;
      }
    }

    /deep/ .el-carousel {
      .el-carousel__button {
        /* width: 0.8vw; */
        /* height: 0.8vw; */
        // border-radius: 50%;
      }
      &__indicators {
        .el-carousel__indicator {
          &--horizontal {
            // background-color: red;
            .el-carousel__button{
              background-color: #D8D8D8;
              border-radius: 50%;
              width: 15px;
              height: 15px;
            }
          }
        }
        .is-active{
          .el-carousel__button{
              background-color: #AF4B42;
            }
          // background-color: red;
        }
      }
    }
  }
}
.block {
  /* height: 100%;
  border: 1px solid red; */
}
.m {
  /* border: 1px solid red; */
}
div {
  overflow: initial;
}
h1 {
  // background-color: skyblue;
  /* height: 100%; */
}
.bg-purple {
  background: darkgray;
  /* height: 100%;
  border: 1px solid red; */
}
.grid-left {
  /* height: 40vw; */
  /* border: 1px solid red; */
}
.brandBox {
  /* padding: 0.5vw; */
  /* border: 1px red solid; */
  // display: grid;
  // grid-template-columns: auto auto;
  // justify-content: space-around;

  /* flex-wrap:wrap; */
  /* justify-content: space-around; */
  /* align-content:flex-start */
}
.brand {
  /* border: 1px red solid; */
  // width: 12vw;
  /* height: 4vw; */
  // margin-bottom: 0.7vw;
}
/deep/ .el-carousel__indicators--horizontal {
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-around;
  // bottom: -2vw;
}

/deep/ .el-carousel__indicator--horizontal {
  /* padding: 0.3vw; */
}
</style>