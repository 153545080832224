<template>
  <div class="news">
    <div class="con" v-for="(item, index) in newsList" :key="index" @click="newsInfo(item.id)">
      <div class="top" v-if="index == 0">
        <div class="head">
          <div class="mctt">门窗头条</div>

          <div class="title">{{ item.title }}</div>
        </div>
        <div class="con">
          <div class="desc" v-html="item.introduction ? item.introduction : item.title"></div>
          <div class="img">
            <img :src="item.titleImgUrl" alt="" />
          </div>
        </div>
      </div>
      <div v-else class="item">
        {{ item.title }}
      </div>
    </div>
  </div>
</template>

<script>
import { getNewList } from "@/api/news/news.js";
export default {
  name: "news",
  data() {
    return {
      newsList: [],
    };
  },
  mounted() {
    var quary = {
        pageNum:1,
        pageSize:5
    }
    getNewList(quary).then((res) => {
      console.log(res);
      this.newsList = res.rows;
    });
  },
  methods:{
    newsInfo(id){
         this.$router.push({path : "/newsInfo" ,query:{id}})
    }
  }
};
</script>

<style scoped lang="scss">
.news {
  // border: 1px solid red;
  width: 100%;
  height: 100%;
  display: grid;
  align-content: space-between;
  .top {
    padding-left: 15px;
    cursor: pointer;
    .head {
      margin-bottom: 10px;
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: space-between;
      .mctt {
        background-color: #af4b42;
        color: white;
        text-align: center;
        padding: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 40px;
        // text-align: justify;
      }
      .title {
        width: 90%;
        font-size: 22px;
        font-weight: 600;

        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }
    }
    .con {
      
      // border: 1px solid red;
      display: grid;
      grid-template-columns: 70% 30%;
      align-items: center;
      gap: 10px;
      // border: 1px solid red;
      
       height: 100px;
      .img {
        height: 100px;
        // border: 1px solid red;
        img {
          
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .desc {
        // border: 1px solid red;
        font-size: 16px;
        color: #8c8c8c;

        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        &:hover{
        color: #af4b42;
    }
      }
    }
  }
  .item {
    padding-left: 15px;
    cursor: pointer;
    // border: 1px solid red;
    font-size: 16px;
    // padding: 50px 0;
    // margin: 5px;

    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    &:hover{
        color: #af4b42;
    }
  }
}
</style>