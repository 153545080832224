<template>
    <div class="search">
        <div class="m">
            <div class="logo">
                <a href="https://www.cnnmumen.com/" title="门窗之家">
                    <img src="../assets/logo.jpg" alt="门窗之家" />
                </a>
                <!-- <br> -->
                <div class="logodes">打造门窗行业专业网站</div>
            </div>

            <div class="search">
                <div>
                    <el-input placeholder="请输入您要查找的内容" v-model="search">
                        <el-select v-model="select" slot="prepend" placeholder="1" class="select">
                            <el-option label="找产品" value="1"></el-option>
                            <el-option label="找新闻" value="2"></el-option>
                            <el-option label="找品牌" value="3"></el-option>
                        </el-select>
                        <el-button slot="append" icon="el-icon-search" class="button" @click="searchClick">搜索</el-button>
                    </el-input>
                </div>

                <!-- <div class="h10"></div> -->

                <!-- <div class="tuijian">
          <img src="../assets/image/tuijian.png" alt="" />
          <a>xxx</a>
          <a>xxx</a>
          <a>xxxxsssssssssssssssxx</a>
          <a>xxxxxxxxxxxxxxxxx</a>
                </div>-->
            </div>

            <!-- <div class="rczp"><img src="@/assets/image/rczp.png" alt="" /></div> -->
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            input1: '',
            input2: '',
            search: '',
            select: '1',
        }
    },
    name: 'Search',
    methods: {
        searchClick() {
                this.$router.push({ path: '/search', query: { type: this.select, search: this.search } })

        },
    },
    watch: {
    },
    mounted() {},
}
</script>

<style scoped lang="scss">
.search {
    overflow: inherit;
}
.m {
    // border: 1px solid red;
    display: grid;
    grid-template-columns: 1fr 2fr 2fr;
    gap: 50px;
    padding: 15px;
    box-sizing: border-box;
    .logo {
        // border: 1px solid red;
        line-height: 0px;
        img {
            width: 200px;
            height: 60px;
        }
        .logodes {
            text-align: center;
            letter-spacing: 6px;
            line-height: 30px;
            font-size: 12px;
            color: #666;
            font-weight: 400;
            /* border: 1px solid red; */
        }
    }
    .search {
        // border: 1px solid red;
        /* margin-top: 15px; */
        /* overflow: initial; */
        /* border: 1px solid #9A3732; */
        // width: 33vw;
        padding: 10px;
        display: grid;
        align-items: center;
        .tuijian {
            display: flex;
            justify-content: left;
            // align-items: center;
            // align-content: center;
            gap: 20px;
            /* border: 1px solid red; */
            height: 25px;
            a {
                /* border: 1px solid red; */
                /* display: block; */
                line-height: 25px;
                // margin-left: 3vw;
            }
            p:hover {
                color: red;
            }
        }
    }
}
.rczp {
    text-align: center;
    line-height: 5vw;
    margin-right: 2vw;
    width: 16vw;
    height: 5.52vw;
    cursor: pointer;
    /* border: 1px red solid;2.89174312 20 6.9*/
}
// .rczp img {
//   width: 100%;
//   height: 100%;
// }
.button {
    /* padding: 10px; */
    /* width: 5vw; */
}

/deep/ .el-input-group {
    border-radius: 14px;
    .select {
        width: 90px;
        display: flex;
        // background-color: red;
    }
    .el-input-group__append {
        color: #fff;
        border: 3px solid $aoyin-color-primary;
        background-color: $aoyin-color-primary;
    }
    .el-input__inner {
        border: 0;
        border-top: 3px solid $aoyin-color-primary;
        border-bottom: 3px solid $aoyin-color-primary;
    }
    .el-input-group__prepend {
        border-top-left-radius: 14px;
        border-bottom-left-radius: 14px;
        color: $aoyin-color-primary;
        border-top: 3px solid $aoyin-color-primary;
        border-left: 3px solid $aoyin-color-primary;
        border-bottom: 3px solid $aoyin-color-primary;
        background-color: #f8efee;
    }
}

// /deep/ .el-input__inner:hover {
//   border-top: 3px solid #9a3732;
//   border-bottom: 3px solid #9a3732;
// }
// /deep/ .el-input__inner:focus {
//   border-top: 3px solid #9a3732;
//   border-bottom: 3px solid #9a3732;
// }
</style>