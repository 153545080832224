import request from '@/utils/request'
export function getproductInfo(query) {
    return request({
        url: "/productInfo/list",
        method: "get",
        params: query,
    });
}

export function getproductDetail(id) {
    return request({
        url: "/productInfo/" + id,
        method: "get",
    });
}
//   ?pageNum=1&pageSize=10&type=首页广告位6